import React, { useState, useEffect, useCallback, Fragment } from 'react';
import styled, { css } from 'styled-components';

import {
  getFirebaseFirestore,
  getFirebaseStorage,
} from '@babelcoder/gatsby-plugin-firebase';

const CourseList = styled.section`
  width: 992px;
  margin: 0 auto;
`;

const CourseItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CourseItemImage = styled.img`
  width: 100px;
`;

export default () => {
  const [registrations, setRegistrations] = useState([]);

  const fetchRegistrations = useCallback(async () => {
    const firestore = await getFirebaseFirestore();
    const firebaseStorage = await getFirebaseStorage();
    const querySnapshot = await firestore
      .collectionGroup('registrations')
      .where('success', '==', false)
      .get();
    const registrations = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const { uid, email, price, slipURL } = doc.data();
        const imageURL = await firebaseStorage
          .refFromURL(slipURL)
          .getDownloadURL();

        return { id: doc.id, uid, email, price, image: imageURL };
      })
    );

    setRegistrations(registrations);
  }, [getFirebaseFirestore, getFirebaseStorage, setRegistrations]);

  const createSubscription = useCallback(
    ({ id, uid, price, email }) => async () => {
      const firestore = await getFirebaseFirestore();

      // Add subscription
      await firestore.doc(`users/${uid}/subscriptions/${id}`).set(
        {
          couponId: null,
          uid,
          email,
          price,
          createdAt: new Date(),
          updatedAt: new Date(),
        },
        { merge: true }
      );

      // Success registration
      await firestore.doc(`users/${uid}/registrations/${id}`).set(
        {
          success: true,
        },
        { merge: true }
      );

      setRegistrations((registrations) =>
        registrations.filter((r) => r.id !== id)
      );
    },
    [getFirebaseFirestore, setRegistrations]
  );

  useEffect(() => {
    fetchRegistrations();
  }, [fetchRegistrations]);

  return (
    <CourseList>
      {registrations.map((registration) => {
        const { id, uid, email, price, image } = registration;

        return (
          <CourseItem key={id}>
            <CourseItemImage src={image} alt="Slip" />
            <div>{uid}</div>
            <div>{email || '-'}</div>
            <div>{id}</div>
            <div>{price}</div>
            <button onClick={createSubscription(registration)}>Confirm</button>
          </CourseItem>
        );
      })}
    </CourseList>
  );
};
